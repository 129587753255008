/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';

import Hyphenation from '../components/shared/Hyphenation';

import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Breadcrumbs from '../components/navigation/Breadcrumbs';

import SeoFields from '../components/page/SeoFields';
import HeroImage from '../components/page/HeroImage';
import Title from '../components/page/Title';
import LeadText from '../components/page/LeadText';
import Content from '../components/page/Content';
import ContactPersons from '../components/page/ContactPersons';
import Categories from '../components/page/Categories';
import Attachments from '../components/page/Attachments';
import Release from '../components/page/Release';
import PublishingDate from '../components/page/PublishingDate';
import SocialMediaSharing from '../components/page/SocialMediaSharing';

import useInitI18next from '../components/hooks/useInitI18next';
import useInitLuxon from '../components/hooks/useInitLuxon';

const styles = theme => ({
  container: {
    display: 'flex',
    marginTop: theme.spacing(9),
  },
  title: {
    paddingBottom: theme.spacing(3),
  },
  publishingDate: {
    marginBottom: theme.spacing(1),
  },
  categories: {
    paddingBottom: theme.spacing(1),
  },
});

function ArticlePageTemplate({ data, classes }) {
  const page = get(data, 'contentfulArticlePage');
  const heroImage = get(page, 'heroImage');
  const breadcrumbs = get(page, 'breadcrumbs');
  const categories = get(page, 'categories');
  const title = get(page, 'title');
  const description = get(page, 'description');
  const publishingDate = get(page, 'publishingDate');
  const content = get(page, 'content');
  const contactPersons = get(page, 'contactPersons');
  const release = get(page, 'release');
  const attachments = get(page, 'attachments');

  useInitI18next();
  useInitLuxon();

  return (
    <>
      <Hyphenation />
      <SeoFields data={page} />
      <Header data={page} />
      <main>
        <Breadcrumbs data={breadcrumbs} />
        <HeroImage data={heroImage} height={430} />
        <Container maxWidth="md" className={classes.container}>
          <Categories data={categories} className={classes.categories} />
          <PublishingDate data={publishingDate} className={classes.publishingDate} />
        </Container>
        <Title data={title} className={classes.title} />
        <LeadText data={description} />
        <Content data={content} />
        <ContactPersons data={contactPersons} />
        <Release data={release} />
        <Attachments data={attachments} />
        <SocialMediaSharing pageData={page} />
      </main>
      <Footer data={page} />
    </>
  );
}

export const pageQuery = graphql`
  query ArticlePageTemplate($id: String!) {
    contentfulArticlePage(id: { eq: $id }) {
      fullPath
      title
      menuTitle
      description
      publishingDate
      topNavigation {
        ...TopNavigationFragment
      }
      breadcrumbs {
        ...BreadcrumbPageFragment
      }
      footer {
        ...FooterFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      categories {
        ...CategoryFragment
      }
      content {
        raw
        references {
          ...ContentReferenceFragment
        }
      }
      release {
        ...ReleaseFragment
      }
      contactPersons {
        ...ContactPersonFragment
      }
      attachments {
        ...AttachmentFragment
      }
      socialMediaSharing {
        ...SocialMediaSharingFragment
      }
      seoFields {
        ...SeoDataFragment
      }
    }
  }
`;

ArticlePageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

ArticlePageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default withStyles(styles)(ArticlePageTemplate);
