import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isEmpty } from 'lodash';
import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';

import AssetModule from '../../module/AssetModule';

const styles = theme => ({
  root: {},
  title: {
    textAlign: 'center',
  },
  release: {
    display: 'flex',
    justifyContent: 'center',
  },
});

function Release({ classes, className, data: release }) {
  if (isEmpty(release)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="md">
        <h2 className={classes.title}>{release.title}</h2>
        <div className={classes.release}>
          <AssetModule data={release} title={release.description} />
        </div>
      </Container>
    </div>
  );
}

Release.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
};

Release.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export const query = graphql`
  fragment ReleaseFragment on ContentfulAsset {
    contentful_id
    description
    title
    file {
      url
      fileName
      contentType
    }
  }
`;

export default withStyles(styles)(Release);
