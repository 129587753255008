import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, isEmpty } from 'lodash';
import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';

import AssetModule from '../../module/AssetModule';

const styles = theme => ({
  root: {
    margin: '40px',
  },
  attachments: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function Attachments({ classes, className, data: attachments }) {
  if (isEmpty(attachments)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="md">
        <div className={classes.attachments}>
          {map(attachments, (attachment, index) => {
            const attachmentId = get(attachment, 'contentful_id');
            const attachmentKey = `${attachmentId}-${index}`;

            return <AssetModule key={attachmentKey} data={attachment} />;
          })}
        </div>
      </Container>
    </div>
  );
}

Attachments.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.array,
};

Attachments.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export const query = graphql`
  fragment AttachmentFragment on ContentfulAsset {
    __typename
    contentful_id
    internal {
      type
    }
    title
    file {
      url
      contentType
      fileName
    }
  }
`;

export default withStyles(styles)(Attachments);
